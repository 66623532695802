import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import mondayService from '../../Services/MondayService';
import Loader from '../Loader/Loader';
import logo from "../../Assets/logo.png";
import './Details.css';
import fileImage from "../../Assets/file.png";
import emptyFileImage from "../../Assets/empty-file.png";

export default function Details() {
    const { id } = useParams();
    const [itemData, setItemData] = useState();
    const [assetData, setAssetData] = useState();
    const dataFetchedRef = useRef(false);

    const fetchData = async () => {
        const sourceItem = await mondayService.getItemDetails(parseInt(id));
        const fileAssetMapping = {};

        if (sourceItem) {
            const assetIds = [];
            sourceItem.column_values.forEach(columnValue => {
                if (columnValue.type === "file") {
                    const fileValue = JSON.parse(columnValue.value);
                    if (fileValue?.files[0].assetId) assetIds.push(fileValue.files[0].assetId);
                }
            });

            const assetFiles = await mondayService.getAssetsById(assetIds);
            assetFiles.forEach(asset => {
                fileAssetMapping[asset.id] = asset.public_url;
            });

        }

        setAssetData(fileAssetMapping);
        setItemData(sourceItem ? sourceItem : "NULL");
    };

    useEffect(() => {
        // to prevent double call
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchData().catch(console.error);
    }, [itemData]);

    const constructDetails = (itemData) => {
        const displaySequenceColumnId = [
            "verbind_borden4", "date7", "locatie", "opmerkingen", "status72", "text0", "text8",
            "text03", "status14", "status73", "status46", "status95", "status9", "file2",
            "bestanden_18", "status25", "bestanden_16", "status24", "datum1", "status7", "status61",
            "bestanden_13", "datum4", "status91", "status5", "status724", "status49", "status84", "bestanden_139", "datum9", "status8", "bestanden_17",
            "tekst1", "telefoon_factuur", "location8", "email_factuur", "text", "status4", "numbers",
        ];
        const displayTextColumnId = ["verbind_borden4"];
        const columnValues = [];

        displaySequenceColumnId.forEach(id => {
            const columnValue = itemData.column_values.find(parameter => parameter.id === id);
            const title = columnValue.column.title;
            let desc = displayTextColumnId.includes(columnValue.id) ? columnValue.display_value : columnValue.text;

            if (columnValue.type === "file") {
                if (columnValue.text) {
                    const fileValue = JSON.parse(columnValue.value);
                    const assetId = fileValue.files[0].assetId;
                    desc = <a href={assetData[assetId]} target='_blank' rel='noopener noreferrer'><img src={fileImage} alt="fileImage" width={25} /></a>
                }
                else {
                    desc = <img src={emptyFileImage} alt="fileImage" width={25} />
                }
            }

            columnValues.push(
                <tr key={columnValue.id} className='col-block'>
                    <td className='col-title'>{title}</td>
                    <td className='col-desc'>{desc}</td>
                </tr>
            );
        });

        return (
            <table>
                <tbody>
                    <tr className='col-block'>
                        <td className='col-title' width={`50%`}>Item</td>
                        <td className='col-desc' width={`50%`}>{itemData.name}</td>
                    </tr>
                    {columnValues}
                </tbody>
            </table>
        );
    };

    return (
        itemData ?
            <>
                <div className='header'>
                    <div className='text-center'><img className='logo' src={logo} alt="logo" /></div>
                </div>

                <div className={`mx-20 detail-container`}>
                    <div className='detail-content'>
                        {
                            itemData !== "NULL" ?
                                constructDetails(itemData)
                                : <div className='text-center'>Item not found!</div>
                        }
                    </div>
                </div>
            </>
            : <div className='loading-container'><Loader /></div>
    );
}