export async function sortByDateDesc(boardItems) {
    boardItems.sort((a, b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0));
    boardItems.reverse();
}
// delay the code to refresh the monday api query cost
export async function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}