import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Components/Dashboard/Dashboard';
import Details from './Components/Details/Details';
import Login from './Components/Login/Login';
import useAuth from './Components/Auth/Auth';

export default function App() {
  const { authData, setAuthData } = useAuth();

  if (!authData) {
    return <Login setAuth={setAuthData} />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard authData={authData} setAuthData={setAuthData} />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter >
  );
}
