import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UpdateModal from '../Modal/UpdateModal';
import fileImage from "../../Assets/file.png";
import emptyFileImage from "../../Assets/empty-file.png";
import infoImage from "../../Assets/info.png";
import './Table.css';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import SendMailModal from '../Modal/SendMailModal';

export default function DatatablePage({ boardData, setBoardData }) {
    const [updateShow, setUpdateShow] = useState(false);
    const [sendMailShow, setSendMailShow] = useState(false);
    const [rowData, setRowData] = useState();
    const [count, setCount] = useState(0);
    const [contentText, setContentText] = useState();

    const handleUpdateClose = () => setUpdateShow(false);
    const handleUpdateShow = (row) => {
        setCount(count + 1);
        setRowData(row);
        setUpdateShow(true);
    };
    const handleSendMailClose = () => {
        setSendMailShow(false);
        setContentText();
    }
    const handleSendMailShow = () => {
        setSendMailShow(true);
    };
    const handleSendEmail = async (data) => {
        handleSendMailShow();
        setContentText();

        try {
            const url = process.env.REACT_APP_SEND_MAIL_URL;
            const result = await axios.post(url, data);
            if (result.status === 200) setContentText("Send mail successfully!!!");
            else setContentText(`Send mail failed with error: ${result.data.message}`);
        } catch (err) {
            setContentText(`Send mail failed with error: ${JSON.stringify(err)}`);
        }
    };

    // column to show in the portal by ids in sequence
    const columnToShowTitle = ["Inspectielocatie", "Datum Asbest / EPC", "Datum ELK / Stookolietank", "Asbestattest", "EPC-attest", "ELK-attest", "Tank-attest"];
    const columnToShow = ["locatie", "datum1", "datum4", "bestanden_18", "bestanden_16", "bestanden_13", "bestanden_139"];
    const columnToEdit = ["bestanden_18", "bestanden_16", "bestanden_13", "bestanden_139"];
    // status and file mapping for file table column colour
    const statusAndFileMapping = {
        "bestanden_18": "status95",
        "bestanden_16": "status25",
        "bestanden_13": "status61",
        "bestanden_139": "status91",
    };

    useEffect(() => {
        const dataTable = document.querySelector('[data-test="datatable-table"]');
        dataTable.setAttribute("class", "dataTableScroll");
    }, [boardData.items]);

    const formCol = (item, col, index) => {
        const columnId = col === "name" ? "name" : col.id;
        const columnTitle = col === "name" ? "Name" : col.column.title;
        const columnType = col === "name" ? "text" : col.type
        const columnText = col === "name" ? item.name : col.text;
        let bgColor = "bg-";

        if (columnType === "file") {
            const fileStatusColumnId = statusAndFileMapping[columnId];
            const fileStatusColumn = item.column_values.find(parameter => parameter.id === fileStatusColumnId);

            if (fileStatusColumn?.text) {
                if (fileStatusColumn.text === "Nee") bgColor += "red";
                else bgColor += "green";
            }
        }

        return (
            columnType === "file" ?
                <div className={`tableCol ${bgColor}`} key={`${col}_${index} `}
                    onClick={columnToEdit.includes(columnId) ? () => {
                        handleUpdateShow({
                            itemId: item.id,
                            columnData: col,
                            columnType: columnType,
                            columnId: columnId,
                            columnTitle: columnTitle,
                            index: index,
                            itemName: item.name,
                        })
                    } : null}
                >
                    <div className='tdAfter fileHolder'>
                        <img src={columnText ? fileImage : emptyFileImage} alt="fileImage" width={25} />
                    </div>
                </div>
                : columnText
        );
    };

    const formRow = () => {
        const rowItems = boardData.items.map((item, index) => {
            const colData = {};
            let bgColor = "bg-";
            let colContent = null;

            for (let i = 0; i < columnToShow.length; i++) {
                const columnId = columnToShow[i];
                const columnData = item.column_values.find(parameter => parameter.id === columnId);

                colData[columnId] = formCol(item, columnData, index);
            }

            // foto's section
            const columnFoto = item.column_values.find(parameter => parameter.id === "status5");
            const columnDrone = item.column_values.find(parameter => parameter.id === "status724");
            const columnMatterport = item.column_values.find(parameter => parameter.id === "status49");
            const column2Dplan = item.column_values.find(parameter => parameter.id === "status84");
            const sendMailData = {
                "itemId": item.id,
                "columnFoto": columnFoto.text,
                "columnDrone": columnDrone.text,
                "columnMatterport": columnMatterport.text,
                "column2Dplan": column2Dplan.text,
            };

            if (columnFoto.text === "Ja" || columnDrone.text === "Ja" || columnMatterport.text === "Ja" || column2Dplan.text === "Ja") {
                bgColor += "green";

                if (columnFoto.text === "Ja") {
                    colContent = <img src={emptyFileImage} alt="fileImage" width={25} />;

                    if (columnDrone.text === "Ja" || columnMatterport.text === "Ja" || column2Dplan.text === "Ja") {
                        colContent = "Ja + Opties";
                    }
                }
            }
            else {
                bgColor += "red";
                colContent = <img src={emptyFileImage} alt="fileImage" width={25} />;
            }

            colData["detail"] = <Link to={`/details/${item.id}`} target='_blank'><img src={infoImage} alt='information' width={25} /></Link>;
            colData["fotos"] = <div className={`tableCol ${bgColor}`}>{colContent}</div>;
            // colData["btnSendEmail"] = <div className={`btnSendEmail btn-primary tableCol`} onClick={() => handleSendEmail(sendMailData)}>Send email foto's</div>;

            return colData;
        });

        return rowItems;
    };

    const formHeader = () => {
        const columnHeader = columnToShowTitle.map((column, index) => {
            return {
                label: column,
                field: columnToShow[index],
                sort: 'asc',
                width: 150
            };
        });

        columnHeader.splice(0, 0, {
            label: "",
            field: "detail",
            sort: 'asc',
            width: 150
        });

        columnHeader.push({
            label: "Foto's",
            field: "fotos",
            sort: 'asc',
            width: 150
        });

        // columnHeader.push({
        //     label: "",
        //     field: "btnSendEmail",
        //     sort: 'asc',
        //     width: 150
        // });

        return columnHeader;
    };

    const data = {
        columns: formHeader(),
        rows: formRow(),
    };

    return (
        <>
            <MDBDataTable
                data={data}
                striped
                searching={true}
            />

            <UpdateModal
                show={updateShow}
                handleClose={handleUpdateClose}
                data={rowData}
                boardData={boardData}
                setBoardData={setBoardData}
                count={count}
            />

            <SendMailModal
                show={sendMailShow}
                handleClose={handleSendMailClose}
                contentText={contentText}
            />
        </>
    );
}