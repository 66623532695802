export const AEL_OPTIONS = ["Asbestattest", "Elektriciteitskeuring", "Energieprestatiecoëfficient (EPC)", "Stookolietank", "Basis fotografie", "Matterport 3D", "2D Plannen"];
export const STOOKOLIETANK_OPTIONS = [
    {
        name: "Stookolietank bovengronds",
        value: "Bovengronds",
    },
    {
        name: "Stookolietank ondergronds",
        value: "Ondergronds",
    },
];

export const FOTOGRAFIE_OPTIONS = [
    {
        name: "Dronefotografie",
        value: "Dronefotografie",
    },
];

export const WONING_OPTIONS = [
    {
        name: "Open",
        value: "Open",
    },
    {
        name: "Halfopen",
        value: "Halfopen",
    },
    {
        name: "Gesloten",
        value: "Gesloten",
    },
    {
        name: "Bijgebouw",
        value: "Bijgebouw",
    },
    {
        name: "Garage",
        value: "Garage",
    },
    {
        name: "Studio",
        value: "Studio",
    },
    {
        name: "Appartement",
        value: "Appartement",
    },
    {
        name: "Renovatie",
        value: "Renovatie",
    },
    {
        name: "Anders",
        value: "Anders",
    },
];

export const APPARTEMENT_OPTIONS = [
    {
        name: "Berging",
        value: "Berging",
    },
    {
        name: "Garage",
        value: "Garage",
    },
    {
        name: "Beide",
        value: "Beide",
    },
    {
        name: "Geen",
        value: "Geen",
    },
];

export const BEZICHTIGING_OPTIONS = [
    {
        name: "Makelaar verleent toegang",
        value: "Makelaar",
    },
    {
        name: "Sleutel ophalen op kantoor",
        value: "Sleutel",
    },
    {
        name: "De bewoner verleent toegang",
        value: "Eigenaar",
    },
    {
        name: "De huurder verleent toegang",
        value: "Huurder",
    },
];

export const FACTUURADRES_OPTIONS = [
    {
        name: "Ja",
        value: "yes",
    },
    {
        name: "Nee",
        value: "no",
    },
];

export const GROUP_OPTIONS = [
    {
        name: "Cexa",
        value: "Cexa",
    },
    {
        name: "Certi",
        value: "Certi",
    },
    {
        name: "Eco Steel",
        value: "Eco Steel",
    },
];

export const STATUS_OPTIONS = [
    {
        name: "Nieuwe Order",
        value: "Nieuwe Order",
    },
    {
        name: "Te Bevestigen",
        value: "Te Bevestigen",
    },
    {
        name: "Af Te Werken",
        value: "Af Te Werken",
    },
    {
        name: "Afgewerkt",
        value: "Afgewerkt",
    },
    {
        name: "Annulatie",
        value: "Annulatie",
    },
];

export const AFSPRAAK_OPTIONS = [
    {
        name: "Voorstel",
        value: "Voorstel",
    },
    {
        name: "Bevestigd",
        value: "Bevestigd",
    },
    {
        name: "Annulatie",
        value: "Annulatie",
    },
    {
        name: "Nic",
        value: "Nic",
    },
    {
        name: "Opnieuw inplannen",
        value: "Opnieuw inplannen",
    },
    {
        name: "Only for testing",
        value: "Only for testing",
    },
];

export const DAGDEEL_OPTIONS = [
    {
        name: "namiddag",
        value: "namiddag",
    },
    {
        name: "voormiddag",
        value: "voormiddag",
    },
];

export const DOCUSIGN_OPTIONS = [
    {
        name: "Verstuurd",
        value: "Verstuurd",
    },
    {
        name: "Getekend",
        value: "Getekend",
    },
    {
        name: "Ter plaatse tekenen",
        value: "Ter plaatse tekenen",
    },
    {
        name: "Niet nodig",
        value: "Niet nodig",
    },
    {
        name: "Geweigerd",
        value: "Geweigerd",
    },
];

export const BETALING_OPTIONS = [
    {
        name: "Verstuurd",
        value: "Verstuurd",
    },
    {
        name: "Betaald",
        value: "Betaald",
    },
    {
        name: "Nog opmaken",
        value: "Nog opmaken",
    },
    {
        name: "Ter plaatse betalen",
        value: "Ter plaatse betalen",
    },
    {
        name: "On Hold",
        value: "On Hold",
    },
    {
        name: "Eerste aanmaning",
        value: "Eerste aanmaning",
    },
    {
        name: "Tweede aanmaning",
        value: "Tweede aanmaning",
    },
    {
        name: "Derde aanmaning",
        value: "Derde aanmaning",
    },
];

export const DISOMA_OPTIONS = [
    {
        name: "Aangemeld",
        value: "Aangemeld",
    },
    {
        name: "Geen staal",
        value: "Geen staal",
    },
    {
        name: "Analyse compleet",
        value: "Analyse compleet",
    },
];

export const CERTIFICAAT_OPTIONS = [
    {
        name: "Working on it",
        value: "Working on it",
    },
    {
        name: "Stuck",
        value: "Stuck",
    },
    {
        name: "Done",
        value: "Done",
    },
];

export const AFGELEVERD_OPTIONS = [
    {
        name: "Enkel makelaar",
        value: "Enkel makelaar",
    },
    {
        name: "Afwachten betaling",
        value: "Afwachten betaling",
    },
    {
        name: "Afgeleverd",
        value: "Afgeleverd",
    },
];

export const COMMISSIE_OPTIONS = [
    {
        name: "Op de lijst",
        value: "Op de lijst",
    },
    {
        name: "Self bill compleet",
        value: "Self bill compleet",
    },
    {
        name: "Part",
        value: "Part",
    },
    {
        name: "Calculate",
        value: "Calculate",
    },
];

export const TWILLIO_OPTIONS = [
    {
        name: "Working on it",
        value: "Working on it",
    },
    {
        name: "Stuck",
        value: "Stuck",
    },
    {
        name: "Send SMS",
        value: "Send SMS",
    },
];

export const YES_NO_OPTIONS = [
    {
        name: "Ja",
        value: "Ja",
    },
    {
        name: "Nee",
        value: "Nee",
    },
];