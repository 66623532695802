import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './SendMailModal.css';
import Loader from '../Loader/Loader';

export default function SendMailModal({ show, handleClose, contentText }) {
    const handleModalClose = () => {
        handleClose();
    }

    return (
        <>
            <Modal show={show} onHide={handleModalClose} className={`sendmail-modal`}>
                <Modal.Header closeButton>
                    <Modal.Title><div className='modal-title px-20'>Send email foto's</div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-20'>
                        {contentText ? contentText : <Loader />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}