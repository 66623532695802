import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
monday.setToken(process.env.REACT_APP_MONDAY_API_TOKEN);
monday.setApiVersion("2023-10");

class MondayService {

  static async getBoardColumns(boardId) {
    try {
      const _query = `query ($boardId: ID!) {            
              boards (ids: [$boardId]) {
                columns {
                  id
                  title
                  type
                }
              }
            }`;

      const variables = { boardId };
      const response = await monday.api(_query, { variables });

      return response.data.boards[0].columns;
    } catch (err) {
      console.log(`Error on get board columns with message: ${err}`);
    }
  }

  static async createItem(board, item, group, values) {
    try {
      const boardId = parseInt(board);
      const itemName = String(item);
      const groupId = String(group);
      const columnValues = JSON.stringify(values);

      const _query = `mutation create_item($boardId: ID!, $itemName: String!, $groupId: String, $columnValues: JSON!) {
        create_item(board_id: $boardId, item_name: $itemName, group_id: $groupId, column_values: $columnValues, create_labels_if_missing: true) {
          id
        }
      }`;

      const variables = { boardId, itemName, groupId, columnValues };
      const response = await monday.api(_query, { variables });

      return response?.data?.create_item?.id;
    } catch (err) {
      console.log(`Error on creating item with message: ${err}`);
    }
  }

  static async getItemDetails(itemId, isSingle = true) {
    try {
      const itemIds = isSingle ? [parseInt(itemId)] : itemId;

      const _query = `query($itemIds: [ID!], ) {
        items (ids: $itemIds, limit: 500) {
          created_at
          id
          name
          column_values {
            column {
              id
              title
            }
            id
            type
            value
            text
            ...on MirrorValue {
              display_value
            }
            ...on DependencyValue {
              display_value
            }
            ...on BoardRelationValue {
              display_value
            }
          }
        }
      }`;

      const variables = { itemIds };
      const response = await monday.api(_query, { variables });

      return isSingle ? response.data.items[0] : response.data.items;
    } catch (err) {
      console.error(err);
    }
  }

  static async getBoardItemsByColumnValue(board, filters = [], cursor = null, fullItems = []) {

    try {
      const boardId = parseInt(board);
      const limit = 50;
      const queryHeaderString = cursor ?
        `query($boardId: ID!) {` :
        `query($boardId: ID!, $filters: [ItemsPageByColumnValuesQuery!]) {`;
      const itemsPageByColumnValuesString = cursor ?
        `items_page_by_column_values (limit: ${limit}, board_id: $boardId, cursor: "${cursor}") {` :
        `items_page_by_column_values (limit: ${limit}, board_id: $boardId, columns: $filters) {`;

      // filter example: [{column_id: "status3", column_values: ["Yes ✔"]}]

      const query = `${queryHeaderString}
        ${itemsPageByColumnValuesString}
          cursor
          items {
            created_at
            id
            name
            column_values {
              column {
                id
                title
              }
              id
              type
              value
              text
              ...on MirrorValue {
                display_value
              }
              ...on DependencyValue {
                display_value
              }
              ...on BoardRelationValue {
                display_value
              }
            }
          }
        }
      }`;

      const variables = { boardId, filters };
      const response = await monday.api(query, { variables });
      fullItems = fullItems.concat(response.data.items_page_by_column_values.items);

      if (response && response.data && response.data.items_page_by_column_values && response.data.items_page_by_column_values.cursor) {
        const resCursor = response.data.items_page_by_column_values.cursor;
        return await this.getBoardItemsByColumnValue(board, filters, resCursor, fullItems);
      }
      else {
        return fullItems;
      }

    } catch (err) {
      console.error(err);
    }
  }

  static async getItemsByColumnValue(board, column, value) {
    try {
      const boardId = parseInt(board);
      const columnId = String(column);
      const columnValue = String(value);

      const _query = `query items_by_column_values ($boardId: ID!, $columnId: String!, $columnValue: String!) {
        items_by_column_values (board_id: $boardId, column_id: $columnId, column_value: $columnValue) {
          id
          name
          created_at
          column_values {
            id
            value
            text
            title
            type
          }
        }
      }`;

      const variables = { boardId, columnId, columnValue };
      const response = await monday.api(_query, { variables });

      return response.data.items_by_column_values;
    } catch (err) {
      console.error(`Error on gett items by column value with message: ${err}`);
    }
  }

  static async changeColumnValues(board, item, values) {
    try {
      const boardId = parseFloat(board);
      const itemId = parseFloat(item);
      const columnValues = JSON.stringify(values);

      const query = `mutation change_multiple_column_values($boardId: ID!, $itemId: ID!, $columnValues: JSON!) {
        change_multiple_column_values(board_id: $boardId, item_id: $itemId, column_values: $columnValues) {
          id
        }
      }
      `;
      const variables = { boardId, itemId, columnValues };

      const response = await monday.api(query, { variables });

      if (response.status_code === 500 || response.hasOwnProperty('error_code')) {
        console.log('Change column value variables ' + JSON.stringify(variables));
        throw response.error_message;
      } else {
        return response;
      }
    } catch (err) {
      console.error(err);
    }
  }

  static async deleteFiles(board, item, columnId) {
    try {
      const boardId = parseInt(board);
      const itemId = parseInt(item);

      const query = `mutation {change_column_value (item_id: ${itemId}, column_id: ${columnId}, board_id: ${boardId}, value: "{\\"clear_all\\": true}") { id }}`;
      const variables = {};

      const response = await monday.api(query, { variables });

      return response.data;
    } catch (err) {
      console.error(err);
    }
  }

  static async getUserById(user) {
    try {
      const userId = parseInt(user);

      const query = `query {
        users (ids: ${userId}) {
          created_at
          id
          name
          email
          phone
        }
      }`;
      const variables = {};

      const response = await monday.api(query, { variables });

      return response?.data?.users[0];
    } catch (err) {
      console.error(err);
    }
  }

  static async uploadFile(item, columnId, file) {
    try {
      const itemId = parseInt(item);

      const query = `mutation add_file($file: File!) {add_file_to_column (item_id: ${itemId}, column_id:"${columnId}" file: $file) {id}}`;
      const variables = { file };

      console.log(variables);

      const response = await monday.api(query, { variables });

      return response.data;
    } catch (err) {
      console.error(err);
    }
  }

  static async getAssetsById(assetIds) {
    const assetIdsString = assetIds.join(", ");

    try {
      const query = `query {
        assets(ids: [${assetIdsString}]) {
          id
          url
          name
          public_url
        }
      }`;
      const variables = {};
      //console.debug("Variables" + JSON.stringify(variables));

      const response = await monday.api(query, { variables });
      return response?.data?.assets;

    } catch (err) {
      console.error(err);
    }
  }
}

export default MondayService;