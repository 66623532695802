import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import mondayService from '../../Services/MondayService';
import Loader from '../Loader/Loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import logo from "../../Assets/logo.png";

async function authenticateUser(email) {
    const boardId = 1151549603;
    const authFilters = [
        { "column_id": "text", "column_values": [email] },
    ];
    const auth = await mondayService.getBoardItemsByColumnValue(boardId, authFilters);

    return auth[0];
}

const Login = ({ setAuth }) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleSubmit = async e => {
        setLoading(true);

        e.preventDefault();
        const authData = await authenticateUser(email);
        // if found data check for the password
        if (authData) {
            const passwordColumn = authData.column_values.find(parameter => parameter.id === "text1");
            if (passwordColumn && passwordColumn.value && passwordColumn.text === password) {
                const indexOfPasswordObj = authData.column_values.map(item => item.column.title).indexOf("Password");
                const clientColumn = authData.column_values.find(parameter => parameter.column.title === "Client");
                const clientId = JSON.parse(clientColumn.value).linkedPulseIds[0].linkedPulseId;
                authData.column_values.splice(indexOfPasswordObj, 1);
                authData.clientId = clientId;
                // set auth data if the password matched
                setAuth(authData);

            }
            else {
                console.log(`Password incorrect!!!`);
                setErrorMessage(`Password incorrect`);
                setLoading(false);
            }
        }
        else {
            console.log(`Account not found!!!`);
            setErrorMessage(`Account not found`);
            setLoading(false);
        }

    }

    return (
        <>
            <div className={`vertical-center ${loading ? "loading" : ""}`}>
                <div className="login-wrapper">
                    <div className='logo-container'><img src={logo} alt="logo" /></div>
                    <form onSubmit={handleSubmit} className="login-form">
                        <div className='text-center error-text'>{errorMessage}</div>
                        <div className="form-section">
                            <div className='section-label'>E-mail</div>
                            <div><input className='text-input' type="email" onChange={e => setEmail(e.target.value)} required /></div>
                        </div>
                        <div className="form-section">
                            <div className='section-label'>Wachtwoord</div>
                            <div><input className='text-input' type="password" onChange={e => setPassword(e.target.value)} required /></div>
                        </div>
                        <div className="form-section">
                            <Button className='login-btn' type="submit">Inloggen</Button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`loader-container ${loading ? "loading" : ""}`}>
                <Loader />
            </div>
        </>
    );
}

export default Login;

Login.propTypes = {
    setAuth: PropTypes.func.isRequired
}