import React, { useState, useEffect, useRef } from 'react';
import mondayService from '../../Services/MondayService';
import Loader from '../Loader/Loader';
import './Dashboard.css';
import Table from '../Table/Table';
import Button from 'react-bootstrap/Button';
import NewOrderModal from '../Modal/NewOrderModal';
import { sortByDateDesc } from '../../Services/GeneralService';
import logo from "../../Assets/logo.png";

export default function Dashboard({ authData, setAuthData }) {
    const [boardData, setBoardData] = useState();
    const dataFetchedRef = useRef(false);
    const [show, setShow] = useState(false);

    const boardId = 1133819397;
    let tempBoardData;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const fetchData = async () => {
        const clientName = authData.column_values.find(parameter => parameter.id === "connect_boards").display_value;
        const boardColumns = tempBoardData ? tempBoardData.columns : await mondayService.getBoardColumns(boardId);
        const boardItemsFilters = [
            { "column_id": "text074", "column_values": [clientName] },
        ];
        const boardItems = await mondayService.getBoardItemsByColumnValue(boardId, boardItemsFilters);

        for (let i = 0; i < boardItems.length; i++) {
            const item = boardItems[i];
            const itemNameInfo = {
                id: "name",
                value: JSON.stringify(item.name),
                text: item.name,
                title: "Name",
                type: "text"
            };
            // add item name to the values for update purpose later
            boardItems[i].column_values.splice(0, 0, itemNameInfo);
        }
        // sort by item creation date / Datum aanvraag
        sortByDateDesc(boardItems);

        const data = {
            columns: boardColumns,
            items: boardItems,
        };

        if (!tempBoardData || tempBoardData.items.length <= boardItems.length) {
            tempBoardData = data;
        }

        setBoardData(tempBoardData);
    };
    const handleLogout = () => {
        localStorage.removeItem('authData');
        setAuthData(null);
    };

    useEffect(() => {
        // to prevent double call
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchData().catch(console.error);
    }, [authData, boardData]);

    return (
        boardData ?
            <>
                <div className='header'>
                    <div className='text-center'><img className='logo' src={logo} alt="logo" /></div>
                    <div className='btn-div m-20'>
                        <div className='btn-newOrder'>
                            <Button variant="primary" onClick={handleShow} className='btn-style'>
                                Nieuwe order
                            </Button>
                        </div>
                        <div className='btn-logout'>
                            <Button variant="warning" onClick={handleLogout} className='btn-style'>
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={`mx-20 dashboard-container`}>
                    <Table
                        boardData={boardData}
                        setBoardData={setBoardData}
                    />
                </div>
                <div className='footer'>
                    <div><b>Email:</b> info@mijnasbestattest.vlaanderen</div>
                    <div><b>Algemeen telefoonnummer:</b> 09 279 34 29</div>
                </div>

                <NewOrderModal
                    show={show}
                    handleClose={handleClose}
                    authData={authData}
                    setBoardData={setBoardData}
                    boardData={boardData}
                    tempBoardData={tempBoardData}
                />
            </>
            : <div className='loading-container'><Loader /></div>
    );
}