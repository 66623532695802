import { useState } from 'react';

export default function useAuth() {
    const getAuth = () => {
        // const sessionAuthDataString = sessionStorage.getItem('authData');
        const localAuthDataString = localStorage.getItem('authData');
        let authData = null

        // if (JSON.parse(sessionAuthDataString)) {
        //     authData = JSON.parse(sessionAuthDataString);
        // }

        if (JSON.parse(localAuthDataString)) {
            authData = JSON.parse(localAuthDataString);
        }

        return authData ? authData : null;
    }

    const [authData, setAuthData] = useState(getAuth());

    const saveAuth = (authData) => {
        // sessionStorage.setItem('authData', JSON.stringify(authData));
        localStorage.setItem('authData', JSON.stringify(authData));
        setAuthData(authData);
    }

    return {
        setAuthData: saveAuth,
        authData,
    };
}