import React, { useState } from 'react';
import './NewOrderModal.css';
import Modal from 'react-bootstrap/Modal';
import mondayService from '../../Services/MondayService';
import axios from "axios";
import Loader from '../Loader/Loader';
import { AEL_OPTIONS, WONING_OPTIONS, APPARTEMENT_OPTIONS, BEZICHTIGING_OPTIONS, FACTUURADRES_OPTIONS, YES_NO_OPTIONS, STOOKOLIETANK_OPTIONS, FOTOGRAFIE_OPTIONS } from '../../Constants/constants';
import { sleep } from '../../Services/GeneralService';
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

export default function NewOrderModal({ show, handleClose, authData, boardData, setBoardData, tempBoardData }) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    const [loading, setLoading] = useState(false);
    const [aelCheckedState, setAelCheckedState] = useState(
        new Array(AEL_OPTIONS.length).fill(false)
    );
    const [woning, setWoning] = useState();
    const [appartement, setAppartement] = useState();
    const [bijgebouw, setBijgebouw] = useState();
    const [stookolietank, setStookolietank] = useState();
    const [fotografie, setFotografie] = useState();
    const [inspectielocatie, setInspectielocatie] = useState();
    const [contactPersoon, setContactPersoon] = useState();
    const [adres, setAdres] = useState();
    const [telefoon, setTelefoon] = useState();
    const [email, setEmail] = useState();
    const [bezichtiging, setBezichtiging] = useState();
    const [opdrachtformulierFile, setOpdrachtformulierFile] = useState();
    const [bestandenFile, setBestandenFile] = useState();
    const [factuuradres, setFactuuradres] = useState();
    const [opmerkingen, setOpmerkingen] = useState();
    const [factuuradresContactPersoon, setFactuuradresContactPersoon] = useState();
    const [factuuradresAdres, setFactuuradresAdres] = useState();
    const [factuuradresTelefoon, setFactuuradresTelefoon] = useState();
    const [factuuradresEmail, setFactuuradresEmail] = useState();
    const [btwNummer, setBtwNummer] = useState();
    const [bleh, setBLEH] = useState();

    const handleModalClose = () => {
        setAelCheckedState(new Array(AEL_OPTIONS.length).fill(false));
        setWoning();
        setAppartement();
        setBijgebouw();
        setStookolietank();
        setFotografie();
        setInspectielocatie();
        setContactPersoon();
        setAdres();
        setTelefoon();
        setEmail();
        setBezichtiging();
        setOpdrachtformulierFile();
        setBestandenFile();
        setFactuuradres();
        setOpmerkingen();
        setFactuuradresContactPersoon();
        setFactuuradresAdres();
        setFactuuradresTelefoon();
        setFactuuradresEmail();
        setBtwNummer();
        handleClose();
        setBLEH();
    }

    const handleFormSubmit = async e => {
        e.preventDefault();
        setLoading(true);

        const todayDate = new Date().toJSON().slice(0, 10);
        let milliseconds = 0;

        const columnValues = {};
        // klanten
        columnValues["verbind_borden4"] = JSON.parse(authData.column_values.find(parameter => parameter.id === "connect_boards").value);
        // search index
        columnValues["text074"] = authData.column_values.find(parameter => parameter.id === "connect_boards").display_value;
        // datum aanvraag
        columnValues["date7"] = { "date": todayDate };
        // order status
        columnValues["status28"] = { "label": "Nieuwe Order" };
        // aelCheckedState
        if (aelCheckedState.includes(true)) {
            aelCheckedState.forEach((option, index) => {
                if (index === 0) columnValues["status95"] = { "label": option === true ? "Ja" : "Nee" };
                if (index === 1) columnValues["status61"] = { "label": option === true ? "Ja" : "Nee" };
                if (index === 2) columnValues["status25"] = { "label": option === true ? "Ja" : "Nee" };
                if (index === 3) columnValues["status91"] = { "label": stookolietank };
                if (index === 4) {
                    columnValues["status5"] = { "label": option === true ? "Ja" : "Nee" };
                    if (fotografie) columnValues["status724"] = { "label": "Ja" };
                }
                if (index === 5) columnValues["status49"] = { "label": option === true ? "Ja" : "Nee" };
                if (index === 6) columnValues["status84"] = { "label": option === true ? "Ja" : "Nee" };
            });
        }
        // woning
        if (woning) columnValues["status14"] = { "label": woning };
        // woning
        if (bleh) columnValues["nummers"] = parseFloat(bleh);
        // appartement
        if (appartement) columnValues["status73"] = { "label": appartement };
        // bijgebouw
        if (bijgebouw) columnValues["status46"] = { "label": bijgebouw };
        // bezichtiging
        if (bezichtiging) columnValues["status72"] = { "label": bezichtiging };
        // inspectielocatie
        if (inspectielocatie) columnValues["text4"] = inspectielocatie;
        // contactPersoon
        if (contactPersoon) {
            columnValues["text0"] = contactPersoon;
            if (factuuradres === "yes") columnValues["tekst1"] = contactPersoon;
        }
        // adres
        if (adres?.address) {
            const location = adres;

            if (!adres?.lat || !adres?.lng) {
                // Get latitude & longitude from address.
                const res = await Geocode.fromAddress(adres.address);
                const { lat, lng } = res.results[0].geometry.location;
                location.lat = lat;
                location.lng = lng;
            }

            columnValues["locatie"] = location;
            if (factuuradres === "yes") columnValues["location8"] = location;
        }
        // telefoon
        if (telefoon) {
            columnValues["text8"] = telefoon;
            if (factuuradres === "yes") columnValues["telefoon"] = telefoon;
        }
        // email
        if (email) {
            columnValues["text03"] = email;
            if (factuuradres === "yes") columnValues["email"] = email;
        }
        // opmerkingen
        if (opmerkingen) columnValues["opmerkingen"] = opmerkingen;
        // factuuradres
        if (factuuradres && factuuradres === "no") {
            const location = factuuradresAdres;

            if (!factuuradresAdres?.lat || !factuuradresAdres?.lng) {
                // Get latitude & longitude from address.
                const res = await Geocode.fromAddress(factuuradresAdres.address);
                const { lat, lng } = res.results[0].geometry.location;
                location.lat = lat;
                location.lng = lng;
            }

            columnValues["tekst1"] = factuuradresContactPersoon;
            columnValues["location8"] = location;
            columnValues["telefoon_factuur"] = factuuradresTelefoon;
            columnValues["email_factuur"] = factuuradresEmail;
            columnValues["text"] = btwNummer;
        }

        // get latest item order id
        const itemOrderDetail = await mondayService.getItemDetails(1163043743);
        const orderId = itemOrderDetail.column_values.find(parameter => parameter.id === "text").text;
        // call monday api to create item after all the value is formed
        const newItemId = await mondayService.createItem(1133819397, orderId, "topics", columnValues);

        if (newItemId) {
            // update the orderId by 1
            const updateValues = {}
            updateValues["text"] = String(parseInt(orderId) + 1);
            await mondayService.changeColumnValues(1163043742, 1163043743, updateValues);

            // opdrachtformulierFile
            if (opdrachtformulierFile) {
                try {
                    milliseconds = 10000;
                    const url = process.env.REACT_APP_UPLOAD_FILE_URL;
                    const formData = new FormData();
                    formData.append("itemId", newItemId);
                    formData.append("columnId", "file2");
                    formData.append("file", opdrachtformulierFile);

                    await axios.post(url, formData);
                } catch (err) {
                    console.log(`Upload opdrachtformulier file (${opdrachtformulierFile.name}) for item: (${newItemId}) failed with err: ${err}`);
                }
            }
            // bestandenFile
            if (bestandenFile) {
                try {
                    milliseconds > 0 ? milliseconds += 5000 : milliseconds = 10000;
                    const url = process.env.REACT_APP_UPLOAD_FILE_URL;
                    const formData = new FormData();
                    formData.append("itemId", newItemId);
                    formData.append("columnId", "bestanden39");
                    formData.append("file", bestandenFile);

                    await axios.post(url, formData)
                } catch (err) {
                    console.log(`Upload opdrachtformulier file (${bestandenFile.name}) for item: (${newItemId}) failed with err: ${err}`);
                }
            }

            sleep(milliseconds);
            // form a standard response and append into the datalist
            try {
                const newItem = await mondayService.getItemDetails(newItemId);
                if (newItem && newItem.id) {
                    const itemInfo = {
                        id: "name",
                        value: JSON.stringify(newItem.name),
                        text: newItem.name,
                        title: "Name",
                        type: "text",
                    };
                    newItem.column_values.splice(0, 0, itemInfo);
                    boardData.items.splice(0, 0, newItem);
                    setBoardData(boardData);
                    tempBoardData = boardData;
                }
            } catch (err) {
                console.log(`Failed to get new created item details with err: ${err}`);
            }
        }

        setLoading(false);
        handleModalClose();
    }

    const handleAelOnChange = (position) => {
        const updatedCheckedState = aelCheckedState.map((item, index) =>
            index === position ? !item : item
        );

        setAelCheckedState(updatedCheckedState);
        if (!updatedCheckedState[3]) setStookolietank();
        if (!updatedCheckedState[4]) setFotografie();
        if (!updatedCheckedState[0]) {
            setWoning();
            setBLEH();
        }
    };

    const handleStookolietankChange = (event) => {
        setStookolietank(event.target.value);
    };

    const handleFotografieChange = (event) => {
        setFotografie(event.target.value);
    };

    const handleWoningChange = (event) => {
        setWoning(event.target.value);
        setBLEH();
        setAppartement();
    };

    const handleAppartementChange = (event) => {
        setAppartement(event.target.value);
    };

    const handleBijgebouwChange = (event) => {
        setBijgebouw(event.target.value);
    };

    const handleBezichtigingChange = (event) => {
        setBezichtiging(event.target.value);
    };

    const handleOpdrachtformulierFileChange = (event) => {
        if (event.target.files) {
            setOpdrachtformulierFile(event.target.files[0]);
        }
    };

    const handleBestandenFileChange = (event) => {
        if (event.target.files) {
            setBestandenFile(event.target.files[0]);
        }
    };

    const handleFactuuradresChange = (event) => {
        setFactuuradres(event.target.value);
    };

    return (
        <>
            <div className={`loader-container ${loading ? "loading" : ""}`}><Loader /></div>
            <Modal show={show} onHide={handleModalClose} className={`new-order-modal ${loading ? "loading" : ""}`}>
                <Modal.Header closeButton>
                    <Modal.Title><div className='modal-title px-20'><h1>Aanvraagformulier</h1></div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-20'>
                        <form onSubmit={handleFormSubmit} className="new-order-form">
                            {/* ael section */}
                            <div className='form-section'>
                                <div className='section-label'>Soort keuring</div>
                                <div className='checkbox-list'>
                                    {AEL_OPTIONS.map((item, index) => {
                                        return (
                                            <div className="checkbox-list-item" key={index}>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id={`ael-checkbox-${index}`}
                                                        name={item}
                                                        value={item}
                                                        checked={aelCheckedState[index]}
                                                        onChange={() => handleAelOnChange(index)}
                                                    />
                                                    <label className='checkbox-text' htmlFor={`ael-checkbox-${index}`}>{item}</label>
                                                </div>

                                                {
                                                    /* sub-option for STOOKOLIETANK */
                                                    index === 3 && aelCheckedState[3] ?
                                                        STOOKOLIETANK_OPTIONS.map(({ name, value }, index) => {
                                                            return (
                                                                <div className='px-20' key={`checkbox-list-item-${index}`}>
                                                                    <div className="checkbox-list-item">
                                                                        <input
                                                                            type="radio"
                                                                            id={`stookolietank-checkbox-${index}`}
                                                                            name="stookolietank-radio"
                                                                            value={value}
                                                                            checked={stookolietank === value}
                                                                            onChange={e => handleStookolietankChange(e)}
                                                                            required
                                                                        />
                                                                        <label className='checkbox-text' htmlFor={`stookolietank-checkbox-${index}`}>{name}</label>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null
                                                }

                                                {
                                                    /* sub-option for Basis fotografie */
                                                    index === 4 && aelCheckedState[4] ?
                                                        FOTOGRAFIE_OPTIONS.map(({ name, value }, index) => {
                                                            return (
                                                                <div className='px-20' key={`checkbox-list-item-${index}`}>
                                                                    <div className="checkbox-list-item">
                                                                        <input
                                                                            type="radio"
                                                                            id={`fotografie-checkbox-${index}`}
                                                                            name="fotografie-radio"
                                                                            value={value}
                                                                            checked={fotografie === value}
                                                                            onChange={e => handleFotografieChange(e)}
                                                                        />
                                                                        <label className='checkbox-text' htmlFor={`fotografie-checkbox-${index}`}>{name}</label>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        : null
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* inspectielocatie section */}
                            <div className='form-section'>
                                <div className='section-group'>
                                    <div><h3>Inspectielocatie</h3></div>
                                    {/* inspectielocatie */}
                                    <div className='group-item'>
                                        <div className='section-label'>Bedrijfsnaam inspectielocatie (optioneel)</div>
                                        <div>
                                            <input
                                                type="text"
                                                className={`text-input`}
                                                onChange={e => setInspectielocatie(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* Contactpersoon */}
                                    <div className='group-item'>
                                        <div className='section-label'>Contactpersoon inspectielocatie*</div>
                                        <div>
                                            <input
                                                type="text"
                                                className={`text-input`}
                                                onChange={e => setContactPersoon(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {/* Adres */}
                                    <div className='group-item'>
                                        <div className='section-label'>Adres inspectielocatie*</div>
                                        <div>
                                            <Autocomplete
                                                required
                                                onChange={e => setAdres({ address: e.target.value })}
                                                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                onPlaceSelected={(place) => {
                                                    if (place?.formatted_address) {
                                                        const addressObj = {
                                                            address: place.formatted_address,
                                                            lat: place.geometry.location.lat(),
                                                            lng: place.geometry.location.lng(),
                                                        };
                                                        setAdres(addressObj);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* Telefoon, E-mail */}
                                    <div className='group-item dual-column'>
                                        <div className='item-column'>
                                            <div className='section-label'>Telefoon inspectielocatie*</div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`text-input`}
                                                    onChange={e => setTelefoon(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='item-column'>
                                            <div className='section-label'>E-mail inspectielocatie*</div>
                                            <div>
                                                <input
                                                    type="email"
                                                    className={`text-input`}
                                                    onChange={e => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Grootte, Bezichtiging */}
                                    <div className='group-item dual-column'>
                                        <div className='item-column flex-col'>
                                            <div className='small-column'>
                                                <div className='section-label'>Soort woning*</div>
                                                <div className='checkbox-list'>
                                                    {WONING_OPTIONS.map(({ name, value }, index) => {
                                                        return (
                                                            <div className="checkbox-list-item" key={index}>
                                                                <input
                                                                    type="radio"
                                                                    id={`grootte-checkbox-${index}`}
                                                                    name="grootte-radio"
                                                                    value={value}
                                                                    checked={woning === value}
                                                                    onChange={e => handleWoningChange(e)}
                                                                    required
                                                                />
                                                                <label className='checkbox-text' htmlFor={`grootte-checkbox-${index}`}>{name}</label>
                                                            </div>
                                                        );
                                                    })}
                                                    {
                                                        /* extra option for Energieprestatiecoëfficient (EPC) from Soort keuring */
                                                        aelCheckedState[2] ?
                                                            <div className="checkbox-list-item" key={WONING_OPTIONS.length + 0}>
                                                                <input
                                                                    type="radio"
                                                                    id={`grootte-checkbox-${WONING_OPTIONS.length + 0}`}
                                                                    name="grootte-radio"
                                                                    value={"Handelspand"}
                                                                    checked={woning === "Handelspand"}
                                                                    onChange={e => handleWoningChange(e)}
                                                                    required
                                                                />
                                                                <label className='checkbox-text' htmlFor={`grootte-checkbox-${WONING_OPTIONS.length + 0}`}>Niet residentieel</label>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        /* extra option for asbestattest and Energieprestatiecoëfficient (EPC) from Soort keuring */
                                                        aelCheckedState[0] || aelCheckedState[2] ?
                                                            <div className="checkbox-list-item" key={WONING_OPTIONS.length + 1}>
                                                                <input
                                                                    type="radio"
                                                                    id={`grootte-checkbox-${WONING_OPTIONS.length + 1}`}
                                                                    name="grootte-radio"
                                                                    value={"Gemene Delen"}
                                                                    checked={woning === "Gemene Delen"}
                                                                    onChange={e => handleWoningChange(e)}
                                                                    required
                                                                />
                                                                <label className='checkbox-text' htmlFor={`grootte-checkbox-${WONING_OPTIONS.length + 1}`}>Gemene Delen</label>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='small-column'>
                                                <div className='section-label'>Bijgebouw &gt; 100m2*</div>
                                                <div className='checkbox-list'>
                                                    {YES_NO_OPTIONS.map(({ name, value }, index) => {
                                                        return (
                                                            <div className="checkbox-list-item" key={index}>
                                                                <input
                                                                    type="radio"
                                                                    id={`bijgebouw-checkbox-${index}`}
                                                                    name="bijgebouw-radio"
                                                                    value={value}
                                                                    checked={bijgebouw === value}
                                                                    onChange={e => handleBijgebouwChange(e)}
                                                                    required
                                                                />
                                                                <label className='checkbox-text' htmlFor={`bijgebouw-checkbox-${index}`}>{name}</label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='item-column'>
                                            <div className='section-label'>Hoe verleent u toegang tot het gebouw?</div>
                                            <div className='checkbox-list'>
                                                {BEZICHTIGING_OPTIONS.map(({ name, value }, index) => {
                                                    return (
                                                        <div className="checkbox-list-item" key={index}>
                                                            <input
                                                                type="radio"
                                                                id={`bezichtiging-radio-${index}`}
                                                                name="bezichtiging-radio"
                                                                value={value}
                                                                checked={bezichtiging === value}
                                                                onChange={e => handleBezichtigingChange(e)}
                                                                required
                                                            />
                                                            <label className='checkbox-text' htmlFor={`bezichtiging-radio-${index}`}>{name}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        /* APPARTEMENT_OPTIONS */
                                        woning === "Appartement" ?
                                            <div className='group-item dual-column'>
                                                <div className='item-column flex-col'>
                                                    <div className='small-column'>
                                                        <div className='section-label'>Is er een berging / garage aanwezig?*</div>
                                                        <div className='checkbox-list'>
                                                            {APPARTEMENT_OPTIONS.map(({ name, value }, index) => {
                                                                return (
                                                                    <div className="checkbox-list-item" key={index}>
                                                                        <input
                                                                            type="radio"
                                                                            id={`appartement-checkbox-${index}`}
                                                                            name="appartement-radio"
                                                                            value={value}
                                                                            checked={appartement === value}
                                                                            onChange={e => handleAppartementChange(e)}
                                                                            required
                                                                        />
                                                                        <label className='checkbox-text' htmlFor={`appartement-checkbox-${index}`}>{name}</label>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }

                                    {
                                        /* GEMENE DELEN and HANDELSPAND OPTIONS */
                                        ["Gemene Delen", "Handelspand"].includes(woning) ?
                                            <div className='group-item dual-column'>
                                                <div className='item-column'>
                                                    <div className='section-label'>Bouwlagen*</div>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className={`text-input`}
                                                            onChange={e => setBLEH(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                            {/* bestanden uploaden section */}
                            <div className='form-section'>
                                <div className='section-group'>
                                    <div><h3>Bestanden uploaden</h3></div>
                                    <div className='group-item'>
                                        <div className='section-label'>Upload uw bestanden (opdrachtformulier, plannen, etc.) (niet verplicht)</div>
                                    </div>
                                    <div className='group-item dual-column'>
                                        <div className='item-column'>
                                            <div className='section-label'>Opdrachtformulier</div>
                                            <div>
                                                <input
                                                    type="file"
                                                    className={`file-input`}
                                                    onChange={e => handleOpdrachtformulierFileChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='item-column'>
                                            <div className='section-label'>Plannen, andere</div>
                                            <div>
                                                <input
                                                    type="file"
                                                    className={`file-input`}
                                                    onChange={e => handleBestandenFileChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* factuuradres*/}
                            <div className='form-section'>
                                <div className='section-group'>
                                    <div><h3>Factuuradres</h3></div>
                                    {/* yes or no */}
                                    <div className='group-item'>
                                        <div className='section-label'>Is het factuuradres hetzelfde als het inspectieadres? *</div>
                                        <div className='checkbox-list'>
                                            {FACTUURADRES_OPTIONS.map(({ name, value }, index) => {
                                                return (
                                                    <div className="checkbox-list-item" key={index}>
                                                        <input
                                                            type="radio"
                                                            name="factuuradres-radio"
                                                            id={`factuuradres-radio-${index}`}
                                                            value={value}
                                                            checked={factuuradres === value}
                                                            onChange={e => handleFactuuradresChange(e)}
                                                            required
                                                        />
                                                        <label className='checkbox-text' htmlFor={`factuuradres-radio-${index}`}>{name}</label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {/* ============================================================================= */}
                                    {
                                        factuuradres === "no" ?
                                            <div>
                                                {/* Contactpersoon */}
                                                <div className='group-item'>
                                                    <div className='section-label'>Contactpersoon factuuradres*</div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className={`text-input`}
                                                            onChange={e => setFactuuradresContactPersoon(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                {/* Adres */}
                                                <div className='group-item'>
                                                    <div className='section-label'>Adres factuuradres*</div>
                                                    <div>
                                                        <Autocomplete
                                                            required
                                                            onChange={e => setFactuuradresAdres({ address: e.target.value })}
                                                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                            onPlaceSelected={(place) => {
                                                                if (place?.formatted_address) {
                                                                    const addressObj = {
                                                                        address: place.formatted_address,
                                                                        lat: place.geometry.location.lat(),
                                                                        lng: place.geometry.location.lng(),
                                                                    };
                                                                    setFactuuradresAdres(addressObj);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Telefoon, E-mail */}
                                                <div className='group-item dual-column'>
                                                    <div className='item-column'>
                                                        <div className='section-label'>Telefoon factuuradres*</div>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className={`text-input`}
                                                                onChange={e => setFactuuradresTelefoon(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='item-column'>
                                                        <div className='section-label'>E-mail factuuradres*</div>
                                                        <div>
                                                            <input
                                                                type="email"
                                                                className={`text-input`}
                                                                onChange={e => setFactuuradresEmail(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* BTW nummer */}
                                                <div className='group-item'>
                                                    <div className='section-label'>BTW-nummer</div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className={`text-input`}
                                                            onChange={e => setBtwNummer(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {/* ============================================================================= */}
                                    {/* yes or no */}
                                    <div className='group-item'>
                                        <div className='section-label'>Opmerkingen</div>
                                        <div>
                                            <textarea
                                                className={`text-input`}
                                                rows={5}
                                                onChange={e => setOpmerkingen(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* submit button section */}
                            <div className='form-section'>
                                <button className='btn-submit' type="submit">Bevestig aanvraag</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleModalClose}>
                    Save Changes
                </Button>
            </Modal.Footer> */}
            </Modal>
        </>
    );
}