import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './UpdateModal.css';
import Loader from '../Loader/Loader';
import mondayService from '../../Services/MondayService';
import axios from "axios";
import {
    AEL_OPTIONS, WONING_OPTIONS, BEZICHTIGING_OPTIONS, GROUP_OPTIONS, STATUS_OPTIONS, AFSPRAAK_OPTIONS, DAGDEEL_OPTIONS,
    DOCUSIGN_OPTIONS, BETALING_OPTIONS, DISOMA_OPTIONS, CERTIFICAAT_OPTIONS, AFGELEVERD_OPTIONS, COMMISSIE_OPTIONS, TWILLIO_OPTIONS,
    STOOKOLIETANK_OPTIONS, YES_NO_OPTIONS
} from '../../Constants/constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sortByDateDesc } from '../../Services/GeneralService';

export default function UpdateModal({ show, handleClose, data, boardData, setBoardData, count }) {
    const itemId = data?.itemId;
    const columnType = data?.columnType;
    const columnId = data?.columnId;
    const columnTitle = data?.columnTitle;
    const index = data?.index;
    const columnData = data?.columnData;
    const itemName = data?.itemName;

    const boardId = 1133819397;
    let arrayOptions = [];

    const [loading, setLoading] = useState(false);
    const [text, setText] = useState();
    const [status, setStatus] = useState();
    const [dropdown, setDropdown] = useState(new Array(10).fill(false));
    const [startDate, setStartDate] = useState(new Date());
    const [file, setFile] = useState();
    const [user, setUser] = useState();
    const [fileDownloadUrl, setFileDownloadUrl] = useState();
    const [fileDownloadName, setFileDownloadName] = useState();

    const handleDropdownOnChange = (position) => {
        const updatedCheckedState = dropdown.map((item, index) =>
            index === position ? !item : item
        );

        setDropdown(updatedCheckedState);
    };

    const handleFileChange = (event) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const handleModalClose = () => {
        setText();
        setStatus();
        setDropdown(new Array(10).fill(false));
        setStartDate(new Date());
        setFile();
        setUser();
        setFileDownloadUrl();
        setFileDownloadName();
        handleClose();
    }

    const handleFormSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        // let milliseconds = 0;
        let res = null;
        const columnValues = {};

        try {
            // text column
            if (text && text !== "" && (columnType === "text" || columnType === "name")) {
                columnValues[columnId] = text;
                res = await mondayService.changeColumnValues(boardId, itemId, columnValues);
            }
            // status column
            if (status && status !== "" && (columnType === "color" || columnType === "dropdown")) {
                columnValues[columnId] = columnType === "color" ? { label: status } : { labels: [status] };
                res = await mondayService.changeColumnValues(boardId, itemId, columnValues);
            }
            // dropdown column
            if (dropdown && dropdown !== "" && columnType === "dropdown") {
                if (dropdown.includes(true)) {
                    const labels = [];
                    dropdown.forEach((option, index) => {
                        if (option === true) labels.push(arrayOptions[index]);
                    });

                    columnValues[columnId] = { labels: labels };
                    res = await mondayService.changeColumnValues(boardId, itemId, columnValues);
                }
            }
            // date column
            if (startDate && startDate !== "" && columnType === "date") {
                columnValues[columnId] = { date: startDate.toJSON().slice(0, 10) };
                res = await mondayService.changeColumnValues(boardId, itemId, columnValues);
            }
            // file column
            if (file && file !== "" && columnType === "file") {
                // remove all old files before creating new one
                try {
                    await mondayService.deleteFiles(boardId, itemId, columnId);
                } catch (err) {
                    console.log(`Failed to delete files for itemId: ${itemId} with err: ${err}`);
                };

                try {
                    // milliseconds = 10000;
                    const url = process.env.REACT_APP_UPLOAD_FILE_URL;

                    var formData = new FormData();
                    formData.append("itemId", itemId);
                    formData.append("columnId", columnId);
                    formData.append('file', file);

                    res = await axios.post(url, formData);
                } catch (err) {
                    console.log(`Upload file (${file.name}) for item: (${itemId}) failed with err: ${err}`);
                }
            }

            if (res) {
                // sleep(milliseconds);
                // form a standard response and append into the datalist
                try {
                    const updatedItem = await mondayService.getItemDetails(itemId);
                    if (updatedItem && updatedItem.id) {
                        const itemInfo = {
                            id: "name",
                            value: JSON.stringify(updatedItem.name),
                            text: updatedItem.name,
                            title: "Name",
                            type: "text",
                        };
                        updatedItem.column_values.splice(0, 0, itemInfo);
                        const index = boardData.items.findIndex(item => item.name === updatedItem.name);
                        boardData.items[index] = updatedItem;
                    }
                } catch (err) {
                    console.log(`Failed to get new updated item details with err: ${err}`);
                }
            }

            if (columnId === "date7") sortByDateDesc(boardData.items);
            // setBoardData(boardData);
            // setLoading(false);
            // handleModalClose();
            window.location.reload();
        } catch (err) {
            console.log(`Failed to update item (${itemId}) column (${columnTitle}) with err: ${err}`);
        }
    }

    const formUpdateFieldByColumnType = (columnType, columnTitle, columnId) => {
        // text column
        if (columnType === "text" || columnType === "name") {
            let inputType = "text";
            if (columnTitle.toLowerCase().includes("email")) inputType = "email";
            return (
                <div className='group-item'>
                    <div className='section-label'>{columnTitle}</div>
                    <div>
                        <input
                            type={inputType}
                            className={`text-input`}
                            onChange={e => setText(e.target.value)}
                            autoFocus
                        />
                    </div>
                </div>
            )
        }
        // ***************************************************************************************************
        // status column
        else if (columnType === "color" || (columnType === "dropdown" && columnId === "dropdown6")) {
            let options = [];

            if (columnId === "status67") {
                options = GROUP_OPTIONS;
                arrayOptions = GROUP_OPTIONS;
            }
            else if (columnId === "status28") {
                options = STATUS_OPTIONS;
                arrayOptions = STATUS_OPTIONS;
            }
            else if (columnId === "status24") {
                options = AFSPRAAK_OPTIONS;
                arrayOptions = AFSPRAAK_OPTIONS;
            }
            else if (columnId === "status7") {
                options = DAGDEEL_OPTIONS;
                arrayOptions = DAGDEEL_OPTIONS;
            }
            else if (columnId === "status9") {
                options = DOCUSIGN_OPTIONS;
                arrayOptions = DOCUSIGN_OPTIONS;
            }
            else if (columnId === "status8") {
                options = BETALING_OPTIONS;
                arrayOptions = BETALING_OPTIONS;
            }
            else if (columnId === "status40") {
                options = DISOMA_OPTIONS;
                arrayOptions = DISOMA_OPTIONS;
            }
            else if (columnId === "status19") {
                options = CERTIFICAAT_OPTIONS;
                arrayOptions = CERTIFICAAT_OPTIONS;
            }
            else if (columnId === "status2") {
                options = AFGELEVERD_OPTIONS;
                arrayOptions = AFGELEVERD_OPTIONS;
            }
            else if (columnId === "status4") {
                options = COMMISSIE_OPTIONS;
                arrayOptions = COMMISSIE_OPTIONS;
            }
            else if (columnId === "status_1") {
                options = TWILLIO_OPTIONS;
                arrayOptions = TWILLIO_OPTIONS;
            }
            else if (columnId === "dropdown6") {
                options = BEZICHTIGING_OPTIONS;
                arrayOptions = BEZICHTIGING_OPTIONS;
            }
            else if (columnId === "status14") {
                options = WONING_OPTIONS;
                arrayOptions = WONING_OPTIONS;
            }
            else if (columnId === "status95" || columnId === "status25" || columnId === "status61") {
                options = YES_NO_OPTIONS;
                arrayOptions = YES_NO_OPTIONS;
            }
            else if (columnId === "status91") {
                options = STOOKOLIETANK_OPTIONS;
                arrayOptions = STOOKOLIETANK_OPTIONS;
            }

            // return the final format
            return (
                <div className='group-item'>
                    <div className='section-label'>{columnTitle}</div>
                    <div className='checkbox-list'>
                        {options.map(({ name, value }, index) => {
                            return (
                                <div className="checkbox-list-item" key={index}>
                                    <input
                                        type="radio"
                                        name='group-radio'
                                        id={`group-radio-${index}`}
                                        value={value}
                                        checked={status === value}
                                        onChange={e => setStatus(e.target.value)}
                                        required
                                    />
                                    <label className='checkbox-text' htmlFor={`group-radio-${index}`}>{name}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        // ***************************************************************************************************
        // dropdown column
        else if (columnType === "dropdown") {
            let options = [];

            if (columnId === "dropdown0") {
                options = AEL_OPTIONS;
                arrayOptions = AEL_OPTIONS;
            }

            // return the final format
            return (
                dropdown ?
                    <div className='group-item'>
                        <div className='section-label'>{columnTitle}</div>
                        <div className='checkbox-list'>
                            {options.map((item, index) => {
                                return (
                                    <div className="checkbox-list-item" key={index}>
                                        <input
                                            type="checkbox"
                                            id={`dropdown-checkbox-${index}`}
                                            name={item}
                                            value={item}
                                            checked={dropdown[index]}
                                            onChange={() => handleDropdownOnChange(index)}
                                        />
                                        <label className='checkbox-text' htmlFor={`dropdown-checkbox-${index}`}>{item}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div> : ""
            );
        }
        // ***************************************************************************************************
        // date column
        else if (columnType === "date") {
            // return the final format
            return (
                <div className='group-item'>
                    <div className='section-label'>{columnTitle}</div>
                    <DatePicker
                        className='date-picker'
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>
            );
        }
        // ***************************************************************************************************
        // file column
        else if (columnType === "file") {
            // return the final format
            return (
                <div className='group-item'>
                    <div className='section-label'>{columnTitle}</div>
                    <div>
                        <input
                            type="file"
                            className={`file-input`}
                            onChange={e => handleFileChange(e)}
                        />
                    </div>
                </div>
            );
        }
        // ***************************************************************************************************
        // multiple-person column
        else if (columnType === "multiple-person") {
            // return the final format
            return (
                <div className='group-item'>
                    <div className='section-label'>{columnTitle}</div>
                    <div>Email: {user ? user.email : ""}</div>
                    <div>Phone: {user ? user.phone : ""}</div>
                </div>
            );
        }
    }

    const formDownloadUrl = async () => {
        if (columnType === "file" && columnData?.value) {
            const fileValues = JSON.parse(columnData.value).files;
            const fileNames = [];
            const fileUrls = [];

            for (let i = 0; i < fileValues.length; i++) {
                const file = fileValues[i];

                if (file.fileType === "ASSET") {
                    const assetIds = [file.assetId];
                    const assetFiles = await mondayService.getAssetsById(assetIds);
                    fileNames.push(assetFiles[0].name);
                    fileUrls.push(assetFiles[0].public_url);
                }
                else if (file.fileType === "DROPBOX") {
                    fileNames.push(file.name);
                    fileUrls.push(file.linkToFile);
                }
            }

            setFileDownloadUrl(fileUrls);
            setFileDownloadName(fileNames);
        }
    }

    const getUserInfo = async () => {
        if (columnType === "multiple-person" && columnData?.value) {
            const userId = JSON.parse(columnData.value).personsAndTeams[0].id;
            const user = await mondayService.getUserById(userId);
            setUser(user);
        }
    };

    const downloadFile = () => {
        fileDownloadUrl.forEach(url => window.open(url));
    };

    useEffect(() => {
        getUserInfo();
        formDownloadUrl();
    }, [count, columnData]);

    return (
        <>
            <div className={`loader-container ${loading ? "loading" : ""}`}><Loader /></div>
            <Modal show={show} onHide={handleModalClose} className={`update-modal ${loading ? "loading" : ""}`}>
                <Modal.Header closeButton>
                    <Modal.Title><div className='modal-title px-20'>{itemName}</div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-20'>
                        <form onSubmit={handleFormSubmit} className="update-form">
                            {/* <div className='form-section'>
                                {`itemId: ${itemId}`}
                                <br />
                                {`columnType: ${columnType}`}
                                <br />
                                {`columnId: ${columnId}`}
                                <br />
                                {`columnTitle: ${columnTitle}`}
                                <br />
                                {`index: ${index}`}
                                <br />
                                {`columnData: ${JSON.stringify(columnData)}`}
                                <br />
                            </div> */}
                            {
                                columnType === "file" && columnData?.text && fileDownloadUrl && fileDownloadName ?
                                    <div className='form-section'>
                                        {
                                            fileDownloadName.map(name => {
                                                return (<div key={name}>{name}</div>);
                                            })
                                        }
                                        <br />
                                        <div className='btn-download' onClick={() => downloadFile()}>Download</div>
                                    </div>
                                    : null
                            }
                            <div className='form-section'>
                                {formUpdateFieldByColumnType(columnType, columnTitle, columnId)}
                            </div>
                            {/* submit button section */}
                            {
                                columnType === "multiple-person" ?
                                    null
                                    :
                                    <div className='form-section'>
                                        <button className='btn-submit' type="submit">Save</button>
                                    </div>
                            }
                        </form>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleModalClose}>
                    Save Changes
                </Button>
            </Modal.Footer> */}
            </Modal>
        </>
    );
}